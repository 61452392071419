import { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { IDataRow, IDemographicsData } from 'interfaces/demographics';
import useWindowDimensions from 'hooks/useWindowDimensions';
import clsx from 'clsx';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import { ESubTable, ETopic } from 'configs/enums';
import { ITopic } from 'interfaces/topic';
import { ITableRowColor } from 'interfaces/common';
import { TABLE_ROW_COLOR } from 'configs/constant';
import { ISubTable } from 'interfaces/subTable';
import CommonService from 'services/commonService';
import { Tooltip, Typography } from '@mui/material';

const WORKING_AGE_VARIABLES_ID: [number, number] = [9, 13];
const EMPLOYED_VARIABLES_ID: [number, number] = [18, 22];

interface DemographicsTableProps {
  demographicsData: IDemographicsData;
  hiddenSubTableIds: number[];
  changedVariableIds: number[];
  onAppendChart: (topicId: number, variableId: number) => void;
  onReloadWithEquationValues: (topicId: number, variableId: number) => void;
  dataInfoHeight: number;
}

const DemographicsTable: React.FC<DemographicsTableProps> = memo((props: DemographicsTableProps) => {
  const { demographicsData, hiddenSubTableIds, changedVariableIds, onAppendChart,onReloadWithEquationValues, dataInfoHeight } = props;

  const { width } = useWindowDimensions();

  const horizontalScrollbarRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const headTopicNameRef = useRef<HTMLDivElement>(null);
  const headUnitRef = useRef<HTMLDivElement>(null);

  const [unitSticky, setUnitSticky] = useState<number>(null);
  const [emptyBodyWidth, setEmptyBodyWidth] = useState<number>(null);
  const [horizontalScrollMargin, setHorizontalScrollMargin] = useState<number>(null);

  useEffect(() => {
    const mainElement: HTMLDivElement = document.querySelector('#authorized-layout-main');
    horizontalScrollbarRef.current?.addEventListener('scroll', handleHorizontalScrollbarScroll);
    mainElement?.addEventListener('scroll', handleMainScroll);
    return () => {
      horizontalScrollbarRef.current?.removeEventListener('scroll', handleHorizontalScrollbarScroll);
      mainElement?.removeEventListener('scroll', handleMainScroll);
    };
  }, []);

  useEffect(() => {
    if (width) {
      setUnitSticky(headTopicNameRef?.current?.offsetWidth);
    }
  }, [width, headTopicNameRef?.current?.offsetWidth]);

  useEffect(() => {
    if (width) {
      setEmptyBodyWidth(headRef?.current?.offsetWidth);
    }
  }, [width, headRef?.current?.offsetWidth]);

  useEffect(() => {
    if (width && headTopicNameRef?.current?.offsetWidth && headUnitRef?.current?.offsetWidth) {
      setHorizontalScrollMargin(headTopicNameRef?.current?.offsetWidth + headUnitRef?.current?.offsetWidth);
    }
  }, [width, headTopicNameRef?.current?.offsetWidth, headUnitRef?.current?.offsetWidth]);

  const handleHorizontalScrollbarScroll = useCallback(() => {
    const mainElement: HTMLDivElement = document.querySelector('#authorized-layout-main');
    if (mainElement) {
      mainElement.scrollLeft = horizontalScrollbarRef.current?.scrollLeft;
    }
  }, [horizontalScrollbarRef.current]);

  const handleMainScroll = useCallback(() => {
    const mainElement: HTMLDivElement = document.querySelector('#authorized-layout-main');
    if (mainElement) {
      horizontalScrollbarRef.current.scrollLeft = mainElement.scrollLeft;
    }
  }, [horizontalScrollbarRef.current]);

  // const getDataRow = (topicId: number, subTableId: number, data: any, year: number)=>{
  //   //console.log(topicId,'666666666666')
  //   //console.log(subTableId,'77777777')
  //   //console.log(year,'888888888888888888888888')
  //   //console.log(data,'9999999999999999')
  // }

  return (
    <Fragment>
      <div className={classes.topScrollBar} style={{ paddingLeft: width > 768 ? horizontalScrollMargin : 20, top: dataInfoHeight }}>
        <div ref={horizontalScrollbarRef}>
          <div style={{ width: headRef?.current?.offsetWidth ? headRef?.current?.offsetWidth - horizontalScrollMargin - 20 : 'unset', height: '100%' }} />
        </div>
      </div>

      {demographicsData ? (
        <div className={classes.tableWrapper}>
          <div className={classes.table}>
            <div className={classes.head} id={`table-head`} ref={headRef} style={{ top: dataInfoHeight + 28 }}>
              <div
                id={`head-topic-name`}
                ref={headTopicNameRef}
                className={clsx(classes.column, classes.topicName)}
                style={{ left: width > 768 ? 0 : 'unset' }}
              >
                <div>
                  <p>Metric</p>
                </div>
              </div>

              <div id={`head-unit`} ref={headUnitRef} className={clsx(classes.column, classes.unit)} style={{ left: width > 768 ? unitSticky : 'unset' }}>
                <p>Unit</p>
              </div>

              {demographicsData?.yearRange?.map((year) => {
                return (
                  <div key={`data-${year}`} className={classes.column}>
                    {year}
                  </div>
                );
              })}
            </div>

            <div className={classes.bodyContainer}>
              <div
                className={clsx(classes.body, { [classes.empty]: !demographicsData?.topic?.length })}
                style={!demographicsData?.topic?.length ? { minWidth: emptyBodyWidth } : null}
              >
                {demographicsData?.topic?.length ? (
                  demographicsData?.topic?.map((topic: ITopic, topicIndex: number) => {
                    const colorTheme: ITableRowColor = TABLE_ROW_COLOR?.[topic.id];
                    const colors = [colorTheme?.primary ?? 'var(--backgroundLight)', colorTheme?.secondary ?? 'var(--backgroundLight)'];
                    let colorIndex = 0;

                    return (
                      <div key={`topic-${topic?.id}`} className={classes.topicWrapper}>
                        <div
                          className={clsx(classes.row, classes.desktopSticky)}
                          style={{
                            zIndex: 2 + topicIndex + topic?.subTables?.length ?? 0,
                            top: dataInfoHeight + 28 + 40,
                            backgroundColor: colors[colorIndex++ % colors.length],
                          }}
                        >
                          <div className={clsx(classes.column, classes.topicName)} style={{ left: 0 }}>
                            <p>{topic?.name ? `${topic?.name} data` : 'N/A'}</p>
                          </div>
                        </div>

                        {topic?.subTables
                          ?.filter((subTable) => !hiddenSubTableIds?.includes(subTable.id))
                          ?.map((subTable: ISubTable, subTableIndex: number) => {
                            return (
                              <div key={`subTable-${subTable?.id}`}>
                                <div
                                  className={clsx(classes.row, classes.desktopSticky, classes.borderTop)}
                                  style={{
                                    zIndex: 2 + topicIndex + subTableIndex,
                                    top: dataInfoHeight + 28 + 40 + 40,
                                    backgroundColor: colors[colorIndex++ % colors.length],
                                  }}
                                >
                                  <div className={clsx(classes.column, classes.subTableName)} style={{ left: 0 }}>
                                    <p>{subTable?.name ? `${subTable?.name}` : 'N/A'}</p>
                                  </div>
                                </div>

                                {subTable?.dataRow?.map((dataRow: IDataRow) => {
                                  return (
                                    <div
                                      key={`data-${dataRow?.id}`}
                                      className={clsx(classes.row, {
                                        [classes.borderTopRed]:
                                          topic?.id === ETopic.LabourForce &&
                                          (WORKING_AGE_VARIABLES_ID[0] === dataRow?.variableId || EMPLOYED_VARIABLES_ID[0] === dataRow?.variableId),
                                        [classes.borderChild]:
                                          topic?.id === ETopic.LabourForce &&
                                          ((WORKING_AGE_VARIABLES_ID[0] <= dataRow?.variableId && WORKING_AGE_VARIABLES_ID[1] >= dataRow?.variableId) ||
                                            (EMPLOYED_VARIABLES_ID[0] <= dataRow?.variableId && EMPLOYED_VARIABLES_ID[1] >= dataRow?.variableId)),
                                        [classes.borderTotal]:
                                          topic?.id === ETopic.LabourForce &&
                                          (WORKING_AGE_VARIABLES_ID[1] === dataRow?.variableId || EMPLOYED_VARIABLES_ID[1] === dataRow?.variableId),
                                      })}
                                      style={{ backgroundColor: colors[colorIndex++ % colors.length] }}
                                    >
                                      <div className={clsx(classes.column, classes.dataName)} style={{ left: 0 }}>
                                        <p>{dataRow?.name ?? 'N/A'}</p>
                                      </div>

                                      <div className={clsx(classes.column, classes.unit)} style={{ left: unitSticky }}>
                                        <p>{dataRow?.unit ?? ''}</p>

                                        {subTable.id === ESubTable.Revised ? (
                                          <>
                                            {dataRow?.tooltip ? (
                                              <Tooltip title={<Typography>{dataRow?.tooltip ?? 'N/A'}</Typography>} arrow>
                                                <span className={classes.tooltip}>?</span>
                                              </Tooltip>
                                            ) : null}
                                            {dataRow?.equation && changedVariableIds?.includes(dataRow.variableId) ? (
                                               <Tooltip title={<Typography>If you click here, the model will revert to using the integrated equation.</Typography>} arrow>
                                                <SecondaryButton
                                                  className={clsx(classes.changeButton,classes.modelButton)}
                                                  onClick={() => onReloadWithEquationValues(dataRow?.topicId, dataRow?.variableId)}
                                                >
                                                  Model
                                                </SecondaryButton>
                                               </Tooltip>
                                              
                                            ) : null}
                                            {dataRow?.changeable ? (
                                              <SecondaryButton
                                                className={classes.changeButton}
                                                onClick={() => onAppendChart(dataRow?.topicId, dataRow?.variableId)}
                                              >
                                                Change
                                              </SecondaryButton>
                                            ) : null}
                                            {!dataRow?.changeable && dataRow?.viewable ? (
                                              <SecondaryButton
                                                className={classes.changeButton}
                                                onClick={() => onAppendChart(dataRow?.topicId, dataRow?.variableId)}
                                              >
                                                View
                                              </SecondaryButton>
                                            ) : null}
                                          </>
                                        ) : null}
                                      </div>

                                      {demographicsData?.yearRange?.map((year) => {
                                        return (
                                          <div
                                            // onClick={() => getDataRow(topic.id, subTable?.id,dataRow, year)}
                                            key={`dataYear-${year}`}
                                            className={clsx(classes.column, {
                                              [classes.changeableValue]:
                                                year >= demographicsData?.forecastYear &&
                                                dataRow?.changeable === true &&
                                                subTable.id === ESubTable.Revised,
                                              [classes.changedValue]: changedVariableIds?.includes(dataRow?.variableId),
                                            })}
                                          >
                                            {dataRow?.data?.[year] != null ? (
                                              <p>{`${CommonService.formatNumber(
                                                dataRow?.data?.[year],
                                                dataRow?.decimalPlaces,
                                                dataRow?.isPercentage
                                              )}`}</p>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })
                ) : (
                  <p>No resources found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
});

export default DemographicsTable;
