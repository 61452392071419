import { IChartData, IData } from 'interfaces/demographics';
import classes from './styles.module.scss';
import { memo, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { Table } from 'reactstrap';
import CommonService from 'services/commonService';
import clsx from 'clsx';
import { PasteIcon } from 'assets';
import InputCustom from 'components/InputCustom';
import ToastService from 'services/toastService';

interface ModalChangeDataTableProps {
  isOpen: boolean;
  data: {
    chartData: IChartData;
    yearRange: [number, number];
    yearRangeViewStep: number[];
  };
  onClose: () => void;
  onSubmit: (data: IData) => void;
}

const ModalChangeDataTable: React.FC<ModalChangeDataTableProps> = memo((props: ModalChangeDataTableProps) => {
  const { isOpen, data, onClose, onSubmit } = props;

  const [yearShowModal, setYearShowModal] = useState<string[]>([]);

  const years = useMemo(
    () =>
      Object.keys(data?.chartData?.baseCaseData?.data ?? {})
        ?.filter((year) => parseInt(year) >= data?.chartData?.forecastYear && parseInt(year) <= data?.yearRange?.[1])
        ?.map((year) => parseInt(year)),
    [data]
  );

  const isPercentageData = useMemo(() => data?.chartData?.revisedData?.isPercentage === true, [data]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        years.reduce((acc, year) => {
          acc[year] = Yup.number().required(`Field is required.`).typeError(`Field is required.`).max(999999999, 'Value to big');
          return acc;
        }, {})
      ),
    [years]
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      ...Object.keys(data?.chartData?.revisedData?.data ?? {})
        ?.filter((key) => years.includes(parseInt(key)))
        ?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: isPercentageData ?  data?.chartData?.revisedData?.data[curr] * 100 : data?.chartData?.revisedData?.data[curr],
          }),
          {}
        ),
    },
  });

  const formData = watch();

  const _onClose = (_, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  const onFillForecastData = (forecastYear: number) => {

    let stn = 0;
    let syr = forecastYear;
    let bv = data?.chartData?.revisedData?.data[forecastYear-1] ?? 0;
    const value = getValues();
    const changedData: IData = {
      [forecastYear-1]: bv,
      ...Object.keys(value)
        ?.filter((key) => value[key] != null && value[key].toString() !== '')
        ?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: parseFloat(`${value[curr]}`) / (isPercentageData ? 100 : 1),
          }),
          {}
        ),
    };
    
    for (let year = data?.chartData?.forecastYear ?? 0; year <= years?.[years.length - 1]; year++) {
      const ep = year === years?.[years?.length - 1] ? year : year - 1;
      if (changedData[year] !== undefined || year === years?.[years?.length - 1]) {
        if (stn > 0) {
          const dif = ((changedData[year] ? changedData[year] : 0) - bv) / (stn + 1);
          for (let k = syr; k <= ep; k++) {
            const newValue = changedData[k - 1] + dif;
            changedData[k] = Math.abs(newValue) < 1e-10 ? 0 : newValue;
          }
        }
        syr = year + 1;
        bv = changedData[syr - 1];
        stn = 0;
      } else {
        if (years.includes(year)) {
          stn += 1;
        }
      }
    }
    reset({
      ...Object.keys(changedData)
        ?.filter((key) => years.includes(parseInt(key)))
        ?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: changedData[curr] * (isPercentageData ? 100 : 1),
          }),
          {}
        ),
    });
  };

  const onClearForecastData = () => {
    reset(
      years.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: null,
        }),
        {}
      )
    );
  };

  const _onSubmit = (data: IData) => {
    if (isPercentageData) {
      Object.keys(data).forEach((year) => {
        data[year] /= 100;
      });
    }
    onSubmit(data);
  };

  const onPasteBaseValue = (year: number) => {
    setValue(`${year}`, isPercentageData ?  data?.chartData?.baseCaseData?.data?.[year] * 100 : data?.chartData?.baseCaseData?.data?.[year] ?? 0);
  };

  const onReverseToBase = () => {
    reset({ ...Object.keys(data?.chartData?.revisedData?.data ?? {})
    ?.filter((key) => years.includes(parseInt(key)))
    ?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: isPercentageData ?  data?.chartData?.revisedData?.data[curr] * 100 : data?.chartData?.revisedData?.data[curr],
      }),
      {}
    ) });
  };

  useEffect(() => {

    const listYear = Object.keys(data?.chartData?.baseCaseData?.data ?? {})
    ?.filter((year) => parseInt(year) >= data?.yearRange?.[0] 
      && parseInt(year) <= data?.yearRange?.[1] );

    const lastYear = listYear[listYear.length - 1];

    const arrayYearShow = [];

    listYear?.forEach((yearItem)=>{
      if(data?.yearRangeViewStep?.includes(parseInt(yearItem)) || lastYear === yearItem){
        arrayYearShow.push(yearItem)
      }
    })
    setYearShowModal(arrayYearShow)

  },[data])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0];
      ToastService.error(`${firstErrorKey} value is required`);
    }
  }, [errors]);

  return (
    <Dialog maxWidth={'xl'} transitionDuration={0.3} open={isOpen} onClose={_onClose} className={classes.container}>
      <DialogTitle>Change {data?.chartData?.revisedData?.name?.toLocaleLowerCase() ?? 'N/A'} forecast values</DialogTitle>

      <DialogContent>
        <form id="form-change-table" onSubmit={handleSubmit(_onSubmit)}>
          <Table striped>
            <thead>
              <tr>
                <th style={{ width: '15%', minWidth: '100px' }}>#</th>
                <th style={{ width: '10%', minWidth: '80px' }}>Year</th>
                <th style={{ width: '15%', minWidth: '100px' }}>Base Case</th>
                <th style={{ width: '15%', minWidth: '100px' }}>CAGR</th>
                <th style={{ width: '20%', minWidth: '150px' }}>Revised</th>
                <th style={{ width: '15%', minWidth: '100px' }}>CAGR</th>
              </tr>
            </thead>

            <tbody>
              {yearShowModal?.map((year, yearIndex) => {
                  const yearNumber = parseInt(year);
                  const watchCurrentYear = watch(year);
                  const watchPreviousYear = watch(`${yearNumber - 1}`);
                  return (
                    <tr key={yearIndex}>
                      <th>
                        {yearIndex === 0 || yearNumber === data?.chartData?.forecastYear
                          ? yearNumber < data?.chartData?.forecastYear
                            ? 'Historic Actual'
                            : 'Forecast Values'
                          : ''}
                      </th>

                      <td>{year ?? 'N/A'}</td>

                      <td>
                        {CommonService.formatNumber(
                          data?.chartData?.baseCaseData?.data?.[yearNumber],
                          data?.chartData?.baseCaseData?.decimalPlaces,
                          data?.chartData?.baseCaseData?.isPercentage
                        )}
                      </td>

                      <td>
                        {data?.chartData?.baseCaseData?.data?.[yearNumber - 1]
                          ? CommonService.formatNumber(
                              data?.chartData?.baseCaseData?.data?.[yearNumber] / data?.chartData?.baseCaseData?.data?.[`${yearNumber - 1}`] - 1,
                              2,
                              true
                            )
                          : '-'}
                      </td>

                      <td>
                        {yearNumber < data?.chartData?.forecastYear ? (
                          CommonService.formatNumber(
                            data?.chartData?.revisedData?.data?.[yearNumber],
                            data?.chartData?.revisedData?.decimalPlaces,
                            data?.chartData?.revisedData?.isPercentage
                          )
                        ) : (
                          <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <InputCustom
                              type="number"
                              step="any"
                              className={clsx(classes.inputContainer, {
                                [classes.percentage]: data?.chartData?.baseCaseData?.isPercentage,
                              })}
                              isPercentage={data?.chartData?.baseCaseData?.isPercentage}
                              placeholder="Value"
                              defaultValue={  formData[yearNumber]?.toString() || ""}
                              inputRef={register(year)}
                              errorMessage={errors?.[yearNumber]?.message}
                            />

                            <Tooltip title="Use base value for this year" placement="right" enterNextDelay={1000}>
                              <IconButton className={classes.pasteButton} onClick={() => onPasteBaseValue(parseInt(year))}>
                                <PasteIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        )}
                      </td>
                      <td>
                        {yearNumber < data?.chartData?.forecastYear
                          ? '-'
                          : yearNumber === data?.chartData?.forecastYear
                          ? !errors?.[year]?.message && watchCurrentYear
                            ? CommonService.formatNumber(
                                watchCurrentYear / (isPercentageData ? 100 : 1) / data?.chartData?.revisedData?.data?.[`${yearNumber - 1}`] - 1,
                                2,
                                true
                              )
                            : '-'
                          : !errors?.[yearNumber]?.message && !errors?.[yearNumber - 1]?.message && watchCurrentYear != null && watchPreviousYear != null
                          ? CommonService.formatNumber(watchCurrentYear / watchPreviousYear - 1, 2, true)
                          : '-'}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </form>
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>

        <SecondaryButton onClick={onClearForecastData} disabled={years?.length < 1}>
          Clear forecast data
        </SecondaryButton>

        <PrimaryButton onClick={() => onFillForecastData(data?.chartData?.forecastYear)} disabled={years?.length < 1}>
          Fill forecast data
        </PrimaryButton>

        <PrimaryButton onClick={onReverseToBase} disabled={years?.length < 1}>
          Reverse to base
        </PrimaryButton>

        <PrimaryButton type="submit" form="form-change-table" disabled={years?.length < 1}>
          Save Change
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalChangeDataTable;
